function ErrorPage(props: any) {
  return (
    <div role="main" className="content">
      <div className="lnln-main">
        <div className="lnln-container">
          <div className="t-a-c"><img src="images/error.png" alt="エラー" width="176" /></div>
          <p className="m-t-40">
            {props.ErrorDescription}
          </p>
        </div>
      </div>
      <div className="footer">
        <footer role="contentinfo">
          <p className="footer__copy"><small>&copy;エムティーアイ</small></p>
        </footer>
      </div>
    </div>
  );
}

export default ErrorPage;
