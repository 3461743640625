import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import { TOKEN_E001_ERR_MSG, TOKEN_E002_ERR_MSG, CONNECT_API_ERR_MSG, ERR_CODE_A015 } from '../config/constant';
import { REFRESH_ACS_TOKEN, CHECK_VIDEO_ONETIME_TOKEN } from '../config/api';
import LoadingVideo from './LoadingVideo';
import { sentryContext, setSentryUser, setSentryBreadcrumb, sendSentryLog, setSentryContext } from '../utils/SentryFuc';
import { isEmpty } from '../utils/Common';

function CheckOneTimeToken(props: any) {
  const { oneTimeToken, setAcsId, setToken, setGroupId, setDisplayName, setChatThreadId, setErrorDescription } = props;
  const navigate = useNavigate();

  //URLにoneTimeTokenの認証
  function checkOneTimeToken() {
    const data = {
      temporaryToken: oneTimeToken,
    };
    axios.post(CHECK_VIDEO_ONETIME_TOKEN, data)
      .then(async (res) => {
        const result = res.data;
        if (result.errorCode === null) {
          //トーケンのの有効期限は30分以内で、トーケンの更新apiを呼び出す
          const formatExpiredOn = moment(result.acsTokenExpiresDTJst).add(-30, 'minutes').format("YYYY/MM/DD HH:mm");
          const nowJst = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));
          const formatNowJst = moment(nowJst).format("YYYY/MM/DD HH:mm");
          // sentry user設定
          setSentryUser(result.patientUid);
          const userInfoContext: sentryContext = {
            contextID: 'userInfo',
            info: {
              patientUid: result.patientUid,
              doctorUid: result.doctorUid,
              diagnosisCode: result.diagnosisCode,
              patientAcsId: result.acsId,
              acsVideoGroupId: result.videoId,
            }
          }
          setSentryContext(userInfoContext);

          const missingItemArr = missingItems(result);
          if (missingItemArr.length === 0) {
            if (formatExpiredOn < formatNowJst) {
              setSentryBreadcrumb({
                type: 'info',
                category: 'refreshAcsToken',
                message: 'acs tokenを更新します。',
                level: 'info',
              });
              try {
                const acsTokenResponse = await axios.get(REFRESH_ACS_TOKEN + '?acsId=' + result.acsId) as any;
                if (acsTokenResponse.data.errorCode === null) {
                  setSentryBreadcrumb({
                    type: 'info',
                    category: 'refreshAcsToken',
                    message: 'acs token更新成功。',
                    level: 'info',
                  });
                  setToken(acsTokenResponse.data.acsToken);
                } else {
                  sendSentryLog('acs token更新失敗。', 'refreshAcsToken', 'log', { 'event_name': 'refresh_acs_token' });
                  setErrorDescription(CONNECT_API_ERR_MSG);
                  navigate("/errPage");
                }
              } catch (e) {
                sendSentryLog('acs token更新失敗。', 'refreshAcsToken', 'log', { 'event_name': 'refresh_acs_token' });
                setErrorDescription(CONNECT_API_ERR_MSG);
                navigate("/errPage");
              }
            } else {
              setToken(result.acsToken);
            }
            setAcsId(result.acsId);
            setGroupId(result.videoId);
            setDisplayName(result.personalFamilyName + ' ' + result.personalFirstName);
            setChatThreadId(result.chatThreadId);
            // sentry送信処理
            sendSentryLog('一時token認証成功。', 'checkTokenSuccess', 'log', { 'event_name': 'check_token' });
            //トーケンの認証成功の時に
            navigate("/video");
          } else {
            // APIレスポンスが足りないの場合
            sendSentryLog('一時token検証APIレスポンスが足りないため、ビデオ通話が失敗しました。足りない項目：' + missingItemArr.join('、'), 'checkTokenResponseFailed', 'log', { 'event_name': 'check_token' });
            setErrorDescription(CONNECT_API_ERR_MSG);
            navigate("/errPage");
          }
        } else {
          //トーケンの認証失敗の時に
          if (result.errorCode === ERR_CODE_A015) {
            setErrorDescription(TOKEN_E002_ERR_MSG);
          } else {
            setErrorDescription(TOKEN_E001_ERR_MSG);
          }
          const sentryContext: sentryContext = {
            contextID: 'checkTokenResult',
            info: {
              errCode: result.errorCode,
              errMessage: result.errorCode === ERR_CODE_A015 ? TOKEN_E002_ERR_MSG : TOKEN_E001_ERR_MSG
            }
          }
          sendSentryLog('一時token認証失敗。', 'checkTokenFail', 'log', { 'event_name': 'check_token' }, [sentryContext]);
          navigate("/errPage");
        }
      })
      .catch((e) => {
        const sentryContext: sentryContext = {
          contextID: 'checkTokenResult',
          info: {
            errCode: e.code ?? e.errorCode,
            errMessage: CONNECT_API_ERR_MSG
          }
        }
        sendSentryLog('一時token認証失敗。', 'checkTokenFail', 'log', { 'event_name': 'check_token' }, [sentryContext]);
        setErrorDescription(CONNECT_API_ERR_MSG);
        navigate("/errPage");
      })
  }

  useEffect(() => {
    setSentryBreadcrumb({
      type: 'info',
      category: 'checkToken',
      message: '一時tokenを認証します。',
      level: 'info',
    });
    //oneTimeTokenがnullの場合は処理
    if (oneTimeToken === null || oneTimeToken === '') {
      setErrorDescription(TOKEN_E001_ERR_MSG);
      navigate("/errPage");
    } else {
      checkOneTimeToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneTimeToken]);

  function missingItems(result: any) {
    let missingItemArr = [];
    if (isEmpty(result.acsToken)) {
      missingItemArr.push('acsToken');
    } 
    if (isEmpty(result.acsId)) {
      missingItemArr.push('acsID');
    }
    if (isEmpty(result.personalFamilyName) && isEmpty(result.personalFirstName)) {
      missingItemArr.push('displayName');
    }
    if (isEmpty(result.videoId)) {
      missingItemArr.push('groupID');
    }
    if (isEmpty(result.chatThreadId)) {
      missingItemArr.push('chatThreadId');
    }
    return missingItemArr;
  }

  return (
    <LoadingVideo />
  )
}

export default CheckOneTimeToken;