import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  usePropsFor,
  VideoGallery,
  ControlBar,
  CameraButton,
  MicrophoneButton,
  EndCallButton,
  useCall,
  ViewScalingMode,
  DevicesButton,
  createStatefulChatClient,
} from '@azure/communication-react';
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { Stack } from '@fluentui/react';
import CallEnded from './CallEnded';
import { sendSentryLog, setSentryBreadcrumb } from '../utils/SentryFuc';
import { ENDPOINT_URL } from '../config';

function CallingComponents(props: any): JSX.Element {
  const videoGalleryProps = usePropsFor(VideoGallery);
  const cameraProps = usePropsFor(CameraButton);
  const deviceProps = usePropsFor(DevicesButton);
  const microphoneProps = usePropsFor(MicrophoneButton);
  const endCallProps = usePropsFor(EndCallButton);
  const scalingMode: ViewScalingMode = "Crop";
  const videoRef = useRef<any>(null);
  const acsTextRef = useRef<any>(null);
  const VideoStreamOptions = {
    scalingMode: scalingMode,
  };
  const [sendMessageSuccessDialogFlag, setSendMessageSuccessDialogFlag] = useState<Boolean>(false);
  const [sendMessageFailureDialogFlag, setSendMessageFailureDialogFlag] = useState<Boolean>(false);
  const [isClickable, setIsClickable] = useState<boolean>(true);
  const [chatThreadClient, setChatThreadClient] = useState<any>(null);

  useEffect(() => {
    sendSentryLog('ビデオ通話アクセス成功。', 'startVideo', 'log', { 'event_name': 'start_video' });
  }, []);

  useLayoutEffect(() => {
    const resizeVideo = (): void => {
      const acsTextArea = acsTextRef.current;
      const iHeight = window.innerHeight - acsTextArea.clientHeight;
      videoRef.current.style.height = iHeight + 'px';
    };

    window.addEventListener('resize', resizeVideo);
    resizeVideo();

    return () => window.removeEventListener('resize', resizeVideo);
  }, []);

  // 通話終了処理
  const call = useCall();
  if (call?.state === "Disconnected") {
    //sentry log
    sendSentryLog('ビデオ通話終了。（通話を切るボタンの押下）', 'endVideo', 'log', { 'event_name': 'end_video' });
    return <CallEnded />;
  }

  //「ビデオ診療への入室を医師に通知する」ボタンのクリック事件
  const handleClick = () => {
    if (isClickable) {
      //3秒以内にクリックを繰り返さない
      setIsClickable(false);
      sendMessageToDoctor();
      if (sendMessageSuccessDialogFlag) {
        setTimeout(() => {
          setIsClickable(true);
        }, 3000);
      } else {
        setIsClickable(true);
      }
    }
  }

  //ビデオ診療への入室を医師に通知する
  async function sendMessageToDoctor() {
    setSentryBreadcrumb({
      type: 'info',
      category: "sendMessageToDoctor",
      message: "ビデオ診療への入室を医師に通知する。",
      level: 'info',
    });
    let realChatThreadClient = chatThreadClient;
    if (chatThreadClient === null || chatThreadClient === undefined) {
      //chatThreadClientを作成する
      realChatThreadClient = await createChatThreadClient();
    }
    if (realChatThreadClient === null || realChatThreadClient === undefined) {
      sendSentryLog('医師に通知失敗。', 'sendMessageToDoctorFailed', 'log', { 'event_name': 'send_message_to_doctor' });
      setSendMessageFailureDialogFlag(true);
    } else {
      const sendMessageRequest = {
        content: '入室しました',
      };
      let msgType = "text";
      let sendMessageOptions = {
        senderDisplayName: props.displayName,
        type: msgType,
      };
      try {
        await realChatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions);
        sendSentryLog('医師に通知成功。', 'sendMessageToDoctorSuccess', 'log', { 'event_name': 'send_message_to_doctor' });
        setSendMessageSuccessDialogFlag(true);
      } catch (error) {
        sendSentryLog('医師に通知失敗。', 'sendMessageToDoctorFailed', 'log', { 'event_name': 'send_message_to_doctor' });
        setSendMessageFailureDialogFlag(true);
      }
    }
  }

  //chatThreadClientを作成する
  async function createChatThreadClient() {
    try {
      //statefulChatClientを作成する
      const statefulChatClient = createStatefulChatClient({
        userId: { communicationUserId: props.acsId },
        displayName: props.displayName,
        endpoint: ENDPOINT_URL,
        credential: new AzureCommunicationTokenCredential(props.token)
      });
      //chatThreadIdによってchatThreadClientを作成する
      if (statefulChatClient) {
        const newChatThreadClient = statefulChatClient.getChatThreadClient(props.chatThreadId);
        setChatThreadClient(newChatThreadClient);
        return newChatThreadClient;
      }
    } catch (e: any) {
      setSendMessageFailureDialogFlag(true);
    }
  }

  // UIコンポネント
  return (
    <div id="videoStack">
      {sendMessageSuccessDialogFlag ? 
        <div className="sendMessageTipDialog">
          <div className='dialog-content'>
            <div className="title">医師にメッセージを送信しました</div>
            <div className="message">このダイアログを閉じて、医師の入室をお待ちください。</div>
            <div className="messagePs">※順に診療しておりますので、メッセージ送信後も医師の入室までお時間をいただく場合がございます。</div>
            <button className="button" onClick={() => setSendMessageSuccessDialogFlag(false)}>閉じる</button>
          </div>
        </div>
      : ""}
      {sendMessageFailureDialogFlag ?
        <div className="sendMessageTipDialog">
          <div className='dialog-content'>
            <div className="title">メッセージの送信に失敗しました</div>
            <div className="message">このダイアログを閉じて、もう一度お試しください。</div>
            <button className="button" onClick={() => setSendMessageFailureDialogFlag(false)}>閉じる</button>
          </div>
        </div>
      : ""}
      <Stack>
        <div className="videoGallery" ref={videoRef}>
          {videoGalleryProps && (
            <VideoGallery
              {...videoGalleryProps}
              layout="floatingLocalVideo"
              remoteVideoViewOptions={VideoStreamOptions}
              localVideoViewOptions={VideoStreamOptions}
            />
          )}
        </div>
        <ControlBar layout="floatingBottom">
          {microphoneProps && <MicrophoneButton {...microphoneProps} />}
          {endCallProps && <EndCallButton {...endCallProps} />}
          {cameraProps && <CameraButton {...cameraProps} />}
          {deviceProps && <DevicesButton {...deviceProps} />}
        </ControlBar>
      </Stack>
      <div className="sendMessageDialog" ref={acsTextRef}>
        <button className="button" onClick={handleClick}>ビデオ診療への入室を医師に通知する</button>
        <div className="title">ボタンをタップすると、医師にチャットで「入室しました」と送信されます。<br />ボタンのタップ後は、そのままこの画面で待機してください。</div>
      </div>
    </div>
  );
}

export default CallingComponents;