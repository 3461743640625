import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import ErrorPage from './components/ErrorPage';
import VideoWindow from './components/VideoWindow';
import CheckOneTimeToken from './components/CheckOneTimeToken';

function App() {
  const [token, setToken] = useState("");
  const [acsId, setAcsId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [chatThreadId, setChatThreadId] = useState("");
  const [errorDescription, setErrorDescription] = useState("");

  //URLにoneTimeTokenの取得
  const params = new URLSearchParams(window.location.search);
  const oneTimeToken = params.get("token") as string;

  return (
    <Routes>
      <Route path="/" element={<CheckOneTimeToken
        oneTimeToken={oneTimeToken}
        setAcsId={setAcsId}
        setToken={setToken}
        setGroupId={setGroupId}
        setDisplayName={setDisplayName}
        setChatThreadId={setChatThreadId}
        setErrorDescription={setErrorDescription}
      />} />
      <Route path="/errPage" element={<ErrorPage ErrorDescription={errorDescription} />} />
      <Route path="/video" element={<VideoWindow
        acsId={acsId}
        token={token}
        groupId={groupId}
        displayName={displayName}
        chatThreadId={chatThreadId}
        setErrorDescription={setErrorDescription}
      />} />
    </Routes>
  )
}

export default App;