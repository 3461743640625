function CallEnded(): JSX.Element {
  return (
    <div id="lnln-wrapper">
      <div role="main" className="content">
        <div className="lnln-main">
          <div className="lnln-container">
            <div className="t-a-c">
              <img src="images/expired.png" alt="このビデオ通話は終了済みまたはトークンが切れています" width="264" />
            </div>
            <ul className="list m-t-40">
              <li>これからビデオ診療予定の場合、お手数ですが、アプリ内チャットから医師にビデオリンクの再発行を依頼してください。</li>
              <li>ビデオ終了後、診療中の医師とのチャットに戻る場合、画面を閉じてルナルナ おくすり便アプリに戻ってください。</li>
              <li>すでに診療が終了している場合、医師とのビデオ通話はできません。</li>
            </ul>
            <p className="m-t-40">ご不明な点がある場合にはルナルナ おくすり便アプリのメニュー内「よくある質問/お問い合わせ」よりお問い合わせをお願いいたします。</p>
          </div>
        </div>
        <div className="footer">
          <footer role="contentinfo">
            <p className="footer__copy"><small>&copy;エムティーアイ</small></p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default CallEnded;
