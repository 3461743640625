function LoadingVideo(): JSX.Element {
  return (
    <div role="main" className="content">
      <div className="lnln-main">
        <div className="lnln-container">
          <div className="t-a-c"><img src="images/loading.png" alt="画面を読み込み中…" width="176" /></div>
          <p className="m-t-40">画面を読み込んでいます。しばらくお待ちください。<br />
            なお、１分以上本画面のままビデオ通話が開始しない場合、画面を閉じて通信環境をご確認のうえ、医師から発行されたリンクより再度入室をお試しください。</p>
          <p className="m-t-40">上記お試しいただいても解決しない場合、お手数ですが医師にビデオに入室できないことをお伝えください。</p>
        </div>
      </div>
      <div className="footer">
        <footer role="contentinfo">
          <p className="footer__copy"><small>&copy;エムティーアイ</small></p>
        </footer>
      </div>
    </div>
  );
}

export default LoadingVideo;