import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FluentThemeProvider,
  DEFAULT_COMPONENT_ICONS,
  CallClientProvider,
  CallAgentProvider,
  CallProvider,
  createStatefulCallClient,
  StatefulCallClient,
} from '@azure/communication-react';
import { initializeIcons, registerIcons } from '@fluentui/react';
import { Call, CallAgent } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import CallingComponents from './CallingComponentsStateful';
import { CONNECT_API_ERR_MSG } from '../config/constant';
import LoadingVideo from './LoadingVideo';
import { setSentryBreadcrumb, sendSentryLog } from '../utils/SentryFuc';
import { isEmpty } from '../utils/Common';

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

function VideoWindow(props: any): JSX.Element {
  const { token, acsId, displayName, groupId, chatThreadId, setErrorDescription } = props;

  const [mediaRequest, setMediaRequest] = useState<Boolean>(false);
  const [statefulCallClient, setStatefulCallClient] = useState<StatefulCallClient>();
  const [callAgent, setCallAgent] = useState<CallAgent>();
  const [call, setCall] = useState<Call>();

  let callAgentRef = useRef(callAgent);

  const navigate = useNavigate();

  const constrains = {
    video: true,
    audio: true,
  };

  //変数が有効なUUIDv4であるかどうかをチェック
  function isNotUuidV4(groupId: any) {
    const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return !regex.test(groupId);
  }

  // Androidの場合、マイクとカメラの権限を強制請求する必要がある
  useEffect(() => {
    const isIOS = (/(ipod|iphone|ipad)/i).test(navigator.userAgent);
    if (!isIOS) {
      navigator.mediaDevices.getUserMedia(constrains).then(() => {
        setMediaRequest(!mediaRequest);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.userAgent]);

  useEffect(() => {
    // ACS情報が足りない場合はエラー画面へ
    if (!isEmpty(token) && !isEmpty(acsId) && !isEmpty(displayName) && !isEmpty(groupId)) {
      const tokenCredential = new AzureCommunicationTokenCredential(token);
      if (callAgent === undefined && statefulCallClient && displayName) {
        const createUserAgent = async () => {
          setSentryBreadcrumb({
            type: 'info',
            category: "startVideo",
            message: "ビデオ通話開始(CallAgentの作成を開始する)。",
            level: 'info',
          });
          setCallAgent(await statefulCallClient.createCallAgent(tokenCredential, { displayName: displayName }))
        }
        createUserAgent();
      }
    } else {
      sendSentryLog('URLからビデオ画面に直接アクセスしたり、ビデオ画面をリフレッシュしたりするため、ビデオ通話が失敗しました。', 'startVideoFailed', 'log', { 'event_name': 'start_video' });
      setErrorDescription(CONNECT_API_ERR_MSG);
      navigate("/errPage");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statefulCallClient, displayName, callAgent]);

  useEffect(() => {
    setStatefulCallClient(createStatefulCallClient({
      userId: { communicationUserId: acsId }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callAgent !== undefined && !isNotUuidV4(groupId)) {
      setCall(callAgent.join({ groupId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAgent]);

  //一分以上待ってもビデオ通話ができない
  useEffect(() => {
    const timer = setTimeout(() => {
      if (callAgentRef.current === undefined) {
        sendSentryLog('1分以上待ってもビデオ通話ができない。', 'startVideoWait', 'log', { 'event_name': 'start_video' });
      }
    }, 60 * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callAgentRef.current = callAgent;
  }, [callAgent]);

  if (callAgent === undefined || isNotUuidV4(groupId)) {
    return <LoadingVideo />
  }

  return (
    <>
      <FluentThemeProvider>
        {statefulCallClient && <CallClientProvider callClient={statefulCallClient}>
          {callAgent && <CallAgentProvider callAgent={callAgent}>
            {call && <CallProvider call={call}>
              <CallingComponents token={token} acsId={acsId} displayName={displayName} chatThreadId={chatThreadId} />
            </CallProvider>}
          </CallAgentProvider>}
        </CallClientProvider>}
        <></>
      </FluentThemeProvider>
    </>
  );
}

export default VideoWindow;